/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import type { GatsbyBrowser } from "gatsby"
import PostHogHelper from './src/utils/posthog-helper';

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  PostHogHelper.analyse();

  // open links in new window
  let links = document.links;
  for (let i = 0; i < links.length; i++) {
    let currentHostname = links[i].hostname
    if (
      typeof window !== 'undefined'
      && currentHostname !== window.location.hostname
      && !currentHostname.includes('bunny.net')
    ) {
      links[i].target = '_blank';
    } 
  }

  let heroSubmenuEl = document.querySelector('.block__hero .submenu')
  if (heroSubmenuEl) {
    // Hero mobile submenu position
    let heroSubmenuEls = heroSubmenuEl.querySelectorAll('.has-submenu')
    for (let i = 0; i < heroSubmenuEls.length; i++) {
      heroSubmenuEls[i].addEventListener('mouseover', function(event) {
        this.parentElement.classList.add('is-open')
      }, false);
      heroSubmenuEls[i].addEventListener('mouseout', function(event) {
        this.parentElement.classList.remove('is-open')
      }, false);
    }
  }


  // Submenus
  let submenuLinksLvl1 = document.querySelectorAll('.menu-desktop .menu-level-1 > .has-submenu > a')
  for (let i = 0; i < submenuLinksLvl1.length; i++) {
    submenuLinksLvl1[i].addEventListener('mouseover', function(event) {
      removeClass(this.closest('li').closest('ul').querySelectorAll('.menu-desktop .menu-level-1 > .has-submenu'), 'is-active')
      this.closest('li').classList.add('is-active')
    }, false);
  }
  let submenuLinksLvl12 = document.querySelectorAll('.menu-desktop .menu-level-2 > .has-submenu > a')
  for (let i = 0; i < submenuLinksLvl12.length; i++) {
    submenuLinksLvl12[i].addEventListener('mouseover', function(event) {
      removeClass(this.closest('li').closest('ul').querySelectorAll('.menu-desktop .menu-level-2 > .has-submenu'), 'is-active')
      this.closest('li').classList.add('is-active')
    }, false);
  }
  
  function removeClass(elements, className) {
    for (let i = 0; i < elements.length; i++) {
      elements[i].classList.remove(className)
    }
  }

  // Check if mouse is over desktop menu
  let menuDesktop = document.querySelector('.menu-desktop');
  if (menuDesktop) {
    const bodyEl = document.querySelector('body');
    if (bodyEl) {
      menuDesktop.addEventListener('mouseleave', function (event) {
        bodyEl.classList.remove('submenu-open');
      }, false);
      menuDesktop.addEventListener('mouseover', function (event) {
        bodyEl.classList.add('submenu-open');
      }, false);
    }
  }

  // Mobile navigation
  const menuToggleEl = document.querySelector('.menu-toggle');
  if (menuToggleEl) {
    menuToggleEl.addEventListener('click', function() {
      let bodyEl = document.getElementsByTagName('html')[0]
    
      if (bodyEl.classList.contains('menu-open')) {
        bodyEl.classList.remove('menu-open')
        menuToggleEl.classList.remove('is-open')
      }
      else {
        bodyEl.classList.add('menu-open')
        menuToggleEl.classList.add('is-open')
      }
    }, false)
  }

  // Close mobile menu on submenu click
  let mobileMenus = document.querySelectorAll('.menu-mobile > li:not(.has-submenu) a, .menu-mobile .has-submenu ul > li:not(.has-submenu) a')
  for (let i = 0; i < mobileMenus.length; i++) {
    mobileMenus[i].addEventListener('click', function(event) {
      document.getElementsByTagName('html')[0].classList.remove('menu-open')
      const toggleEl = document.querySelector('.menu-toggle');
      if (toggleEl) {
        toggleEl.classList.remove('is-open')
      }
    }, false)
  }

  // Mobile submenus
  let mobileSubmenus = document.querySelectorAll('.menu-mobile .has-submenu > a')
  for (let i = 0; i < mobileSubmenus.length; i++) {
    mobileSubmenus[i].addEventListener('click', function(event) {
      event.preventDefault()

      if (this.closest('li').classList.contains('is-open')) {
        this.closest('li').classList.remove('is-open')
      }
      else {
        this.closest('li').classList.add('is-open')
      }
    }, false)
  }

  // Reviews animation
  let reviewsEl = document.querySelector('.block__reviews')
  if (reviewsEl) {
    let reviewsRowsEl = document.querySelectorAll('.reviews-row-wr')
    reviewsRowsEl.forEach(row => {
      const rowEl = row.querySelector('.reviews-row');
      if (rowEl) {
        row.style.height = rowEl.clientHeight + 'px'
      }
    })
  }

  // Scroll events
  let lastScrollTop = 0;
  const mainMenuEl = document.querySelector('.site-header')

  function navScroll() {
    const pageYOffset = typeof window !== 'undefined' ? window.pageYOffset : 0;
    let currentScroll = pageYOffset || document.documentElement.scrollTop;

    if (!document.body.className.match('page--enterprise-cdn')) {
      if (currentScroll > lastScrollTop) {
        // Scroll down
        if (mainMenuEl) {
          mainMenuEl.classList.remove('slide-down')

          if (currentScroll > 150) {
            mainMenuEl.classList.add('is-hidden')
          }
          if (currentScroll > 200) {
            mainMenuEl.classList.add('is-sticky')
          }
        }
      } 
      else {
        // Scroll up
        if (mainMenuEl) {
          if (currentScroll > 200) {
            mainMenuEl.classList.add('slide-down')
          }
          else {
            mainMenuEl.classList.remove('slide-down')
          }
    
          if (currentScroll < 150) {
            mainMenuEl.classList.remove('is-sticky')
            mainMenuEl.classList.remove('is-hidden')
          }
        }
      }
    }

    // For Mobile or negative scrolling
    lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('scroll', navScroll)
  }
  
  // Parallax
  const parallaxElements = document.querySelectorAll('.parallax')
  // The parallax function
  const parallax = elements => {
    if ('undefined' !== elements && elements.length > 0) {
      elements.forEach( element => {
        const innerHeight = typeof window !== 'undefined' ? window.innerHeight : 0;
        let y = innerHeight - element.getBoundingClientRect().top
        if (y > 0) {
          element.style.transform = 'translate3d(0, ' + (0.08 * y) + 'px ,0)'
        }
      })
    }
  }
  //If element is in viewport, set its position
  parallax(parallaxElements)
  //Call the function on scroll
  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      parallax(parallaxElements)
    }
  }

  // Remove title tag from image element on hover
  let allImages = document.querySelectorAll('img')
  allImages.forEach( image => {
    image.addEventListener('mouseover', function(event) {
      this.removeAttribute('title')
    });
  })

  // Feedback scroll into view
  const blockHeroCta = document.querySelector('.block__hero .cta');
  if (blockHeroCta) {
    blockHeroCta.addEventListener("click", function() {
      bunnyScrollIntoView(this.href);
    });
  }

  function findElement (selector) {
    if (typeof selector !== 'string') return;

    const scrollSelector = selector.match(/#(.+)/);
    if (!scrollSelector) return;

    try {
      var scrollElement = document.querySelector('.' + scrollSelector[1]);
      if (!scrollElement) {
        scrollElement = document.querySelector('#' + scrollSelector[1]);
      }
      return scrollElement;
    } catch (error) {
      // something went wrong and we can't find the element
      return;
    }
  }

  function bunnyScrollIntoView (selector) {
    const scrollElement = findElement(selector);

    if (!scrollElement) return;

    if (scrollElement.classList.contains('accordion')) {
      const titleElement = scrollElement.querySelector('.title')
      if (titleElement) {
        titleElement.click();
      }
    }

    const startScrolling = () => {
      if (scrollElement) {
        document.documentElement.scrollTop = 0;
        setTimeout(() => {
          scrollElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          });
        }, 200);
      }
    }

    startScrolling();
  }

  const scrollIntoURL = typeof window !== 'undefined' ? window.location.href : '';
  bunnyScrollIntoView(scrollIntoURL);

  // Close country dropdown
  document.onclick = function(event) {
    let selectedCountry = document.querySelector('.selected-country')
    const closestCountry = event?.target?.closest('.selected-country')

    if (selectedCountry && !closestCountry) {
      const flagsEl = document.querySelector('.flags-wr')
      if (flagsEl) {
        flagsEl.classList.add('is-hidden')
      }
    }
  };
}

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: {
    location
  },
  getSavedScrollPosition
}) => {
  const currentPosition = getSavedScrollPosition(location)

  if (
    typeof window !== 'undefined'
    && !location.state
    || !location.state.stopScroll
  ) {
    window.scrollTo(...(currentPosition || [0, 0]))
  }

  return false
}